import React, { useState, useEffect } from 'react';
import { Key, X, AlertCircle } from 'lucide-react';

const AuthModal = ({ onClose, onSubmit, error: externalError }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  // Use external error if provided
  useEffect(() => {
    if (externalError) {
      setError(externalError);
    }
  }, [externalError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!token.trim()) {
      setError('Please enter a token');
      return;
    }
    setError('');
    onSubmit(token);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 transition-opacity bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative w-full max-w-md transition-all transform">
        <div className="relative overflow-hidden bg-white shadow-2xl rounded-2xl">
          <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" />
          
          <button
            onClick={onClose}
            className="absolute p-1 text-gray-400 transition-all rounded-full top-4 right-4 hover:text-gray-600 hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>

          <div className="p-8">
            <div className="flex justify-center mb-4">
              <div className="p-3 bg-blue-50 rounded-xl">
                <Key className="w-8 h-8 text-blue-500" />
              </div>
            </div>

            <h2 className="mb-2 text-2xl font-bold text-center text-gray-800">
              Authorization Required
            </h2>
            <p className="mb-6 text-center text-gray-600">
              Please enter your authorization token
            </p>

            <form onSubmit={handleSubmit} className="space-y-5">
              <div className="relative">
                <label htmlFor="token" className="block mb-1 ml-1 text-sm font-medium text-gray-700">
                  Token
                </label>
                <input
                  id="token"
                  type="text"
                  value={token}
                  onChange={(e) => {
                    setToken(e.target.value);
                    setError('');
                  }}
                  className="block w-full px-4 py-3 text-gray-900 transition-all duration-200 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 placeholder:text-gray-400 bg-gray-50 hover:bg-gray-50/75"
                  placeholder="Enter your authorization token"
                  required
                />
              </div>

              {error && (
                <div className="flex items-center p-3 text-sm text-red-600 rounded-lg bg-red-50">
                  <AlertCircle className="flex-shrink-0 w-5 h-5 mr-2" />
                  <p>{error}</p>
                </div>
              )}

              <button
                type="submit"
                className="flex items-center justify-center w-full px-4 py-3 text-white transition-all duration-200 bg-blue-600 rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Authorize
              </button>
            </form>
          </div>

          <div className="px-8 py-4 border-t border-gray-100 bg-gray-50">
            <p className="text-sm text-center text-gray-600">
              Contact administrator for token access
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;