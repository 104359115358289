import React, { useState, useEffect, useRef } from 'react';
import { Bell, LogOut, Upload, Link2, X, CheckCircle2, Key, FileUp, AlertCircle, Loader2, Shield } from 'lucide-react';
import axios from 'axios';
import logo from "../logo.png";
import AuthModal from './AuthModal';

const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const bgColor = type === 'success' ? 'bg-green-500' : type === 'error' ? 'bg-red-500' : 'bg-blue-500';

  return (
    <div className={`fixed top-4 right-4 z-50 flex items-center p-4 ${bgColor} text-white rounded-lg shadow-lg transform transition-all duration-500 animate-slide-in`}>
      {type === 'success' ? (
        <CheckCircle2 className="w-5 h-5 mr-2" />
      ) : type === 'error' ? (
        <AlertCircle className="w-5 h-5 mr-2" />
      ) : (
        <Bell className="w-5 h-5 mr-2" />
      )}
      <span className="mr-4">{message}</span>
      <button onClick={onClose} className="ml-2 hover:opacity-80">
        <X className="w-4 h-4" />
      </button>
    </div>
  );
};

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [userRole, setUserRole] = useState('public');
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [notification, setNotification] = useState(null);
  const [tokenError, setTokenError] = useState('');

  const fileInputRef = useRef(null);

  useEffect(() => {
    const savedToken = localStorage.getItem('authToken');
    const savedRole = localStorage.getItem('userRole');
    
    if (savedToken && savedRole) {
      setAuthToken(savedToken);
      setUserRole(savedRole);
    } else if (process.env.REACT_APP_PUBLIC_TOKEN) {
      setAuthToken(process.env.REACT_APP_PUBLIC_TOKEN);
      setUserRole('public');
    }
  }, []);

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
  };

  const handleDragEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    handleDragEvents(e);
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setError('');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!file || isUploading) return;

    setIsUploading(true);
    setError('');
    setUploadProgress(0);
  
    const formData = new FormData();
    formData.append('file', file);
  
    const currentToken = authToken === process.env.REACT_APP_PUBLIC_TOKEN && userRole !== 'public' 
      ? localStorage.getItem('authToken') 
      : authToken;
      
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${currentToken}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      console.log("Upload response:", response.data);

      let fileLink;
      if (response.data.accessPath) {
        fileLink = `${process.env.REACT_APP_API_BASEURL}${response.data.accessPath}`;
      } else {
        fileLink = `${process.env.REACT_APP_API_BASEURL}/file/${response.data.fileId}/${encodeURIComponent(response.data.originalName)}`;
      }
      
      setLink(fileLink);
      setFile(null);
      showNotification('File uploaded successfully!', 'success');
    } catch (err) {
      console.error("Upload error:", err);
      console.error("Error details:", err.response?.data);
      
      if (err.response?.status === 401) {
        setError('Authentication failed. Please login again.');
        if (process.env.REACT_APP_PUBLIC_TOKEN) {
          console.log("Retrying with fresh public token");
          setAuthToken(process.env.REACT_APP_PUBLIC_TOKEN);
          localStorage.removeItem('authToken');
          localStorage.setItem('userRole', 'public');
          setUserRole('public');
        } else {
          logoutUser();
        }
      } else {
        setError(err.response?.data?.error || 'Upload failed. Please try again.');
      }
      showNotification('Upload failed: ' + (err.response?.data?.error || 'Unknown error'), 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopySuccess(true);
      showNotification('Link copied to clipboard!', 'success');
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      setError('Failed to copy link');
      showNotification('Failed to copy link', 'error');
    }
  };

  const verifyToken = async (token) => {
    try {
      console.log(`Verifying token: Bearer ${token}`);
      
      try {
        await axios.get(`${process.env.REACT_APP_API_BASEURL}/tokens`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Auth-Attempt': 'true'
          }
        });
        return { success: true, message: '', role: 'admin' };
      } catch (err) {
        if (err.response?.status === 403) {
          const healthCheck = await axios.get(`${process.env.REACT_APP_API_BASEURL}/health`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'X-Auth-Attempt': 'true'
            }
          });
          
          if (healthCheck.status === 200) {
            return { success: true, message: '', role: 'user' };
          }
        }
        return { success: false, message: 'Invalid authentication token' };
      }
    } catch (err) {
      console.error("Token verification error:", err.response?.status, err.response?.data);
      return { success: false, message: 'Invalid authentication token' };
    }
  };

  const handleTokenSubmit = async (token) => {
    setTokenError('');
    
    try {
      const verification = await verifyToken(token);
      
      if (verification.success) {
        try {
          await axios.get(`${process.env.REACT_APP_API_BASEURL}/tokens`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          setUserRole('admin');
          localStorage.setItem('userRole', 'admin');
          localStorage.setItem('authToken', token);
          setAuthToken(token);
          setShowAuthModal(false);
          showNotification('Login successful! Role: Administrator', 'success');
        } catch (err) {
          if (err.response?.status === 403) {
            setUserRole('user');
            localStorage.setItem('userRole', 'user');
            localStorage.setItem('authToken', token);
            setAuthToken(token);
            setShowAuthModal(false);
            showNotification('Login successful! Role: User', 'success');
          } else {
            setTokenError('Invalid or expired token');
            showNotification('Authentication failed: Invalid token', 'error');
          }
        }
      } else {
        setTokenError(verification.message || 'Invalid token');
        showNotification('Authentication failed: Invalid token', 'error');
      }
    } catch (err) {
      console.error("Login error:", err);
      setTokenError('Connection error. Please try again.');
      showNotification('Connection error. Please try again.', 'error');
    }
  };

  const logoutUser = () => {
    if (process.env.REACT_APP_PUBLIC_TOKEN) {
      setAuthToken(process.env.REACT_APP_PUBLIC_TOKEN);
      setUserRole('public');
      localStorage.setItem('authToken', process.env.REACT_APP_PUBLIC_TOKEN);
      localStorage.setItem('userRole', 'public');
    } else {
      setAuthToken('');
      setUserRole('public');
      localStorage.removeItem('authToken');
      localStorage.removeItem('userRole');
    }
    showNotification('Logged out successfully', 'success');
  };

  return (
    <div className="min-h-screen p-6 bg-gradient-to-br from-blue-50 to-gray-100">
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <img src={logo} alt="Logo" className="w-auto h-12" />
          <div className="flex items-center gap-4">
            {userRole !== 'public' || (authToken && authToken !== process.env.REACT_APP_PUBLIC_TOKEN) ? (
              <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <Shield className={`w-4 h-4 mr-2 ${userRole === 'admin' ? 'text-purple-600' : 'text-blue-600'}`} />
                  <span className="text-sm font-medium text-gray-700">
                    {userRole === 'admin' ? 'Admin' : userRole === 'user' ? 'User' : 'Public'}
                  </span>
                </div>
                <button
                  onClick={logoutUser}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
                >
                  <LogOut className="w-4 h-4 mr-2" />
                  Logout
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowAuthModal(true)}
                className="flex items-center px-4 py-2 text-sm font-medium text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                <Key className="w-4 h-4 mr-2" />
                Login
              </button>
            )}
          </div>
        </div>

        <div className="p-8 bg-white shadow-xl rounded-2xl">
          <h1 className="mb-8 text-3xl font-bold text-center text-gray-800">
            Secure File Upload
          </h1>

          <div
            className={`relative border-2 border-dashed rounded-xl transition-all duration-200 
            ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}
            p-8 text-center`}
            onDragEnter={(e) => {
              handleDragEvents(e);
              setIsDragging(true);
            }}
            onDragLeave={(e) => {
              handleDragEvents(e);
              setIsDragging(false);
            }}
            onDragOver={handleDragEvents}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
            />

            <div className="flex flex-col items-center justify-center space-y-4">
              <FileUp 
                onClick={() => fileInputRef.current?.click()}
                className="w-16 h-16 cursor-pointer text-blue-500" 
              />

              <button
                onClick={() => fileInputRef.current?.click()}
                className="text-lg font-medium text-gray-700 transition-colors hover:text-blue-600"
              >
                Click to upload or drag and drop
              </button>
              <p className="text-sm text-gray-500">
                Upload one file at a time
                {userRole === 'public' && (
                  <span className="block mt-1 text-amber-600">
                    Public uploads expire after 7 days
                  </span>
                )}
              </p>

              {file && (
                <div className="flex items-center p-3 mt-4 rounded-lg bg-gray-50">
                  <FileUp className="w-5 h-5 mr-2 text-gray-500" />
                  <span className="text-sm text-gray-600">{file.name}</span>
                  <button
                    onClick={() => setFile(null)}
                    className="p-1 ml-2 transition-colors rounded-full hover:bg-gray-200"
                  >
                    <X className="w-4 h-4 text-gray-500" />
                  </button>
                </div>
              )}
            </div>
          </div>

          <button
            onClick={handleUpload}
            disabled={!file || isUploading}
            className="flex items-center justify-center w-full px-6 py-3 mt-6 text-white transition-all duration-200 bg-blue-500 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isUploading ? (
              <>
                <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                Uploading...
              </>
            ) : (
              <>
                <Upload className="w-5 h-5 mr-2" />
                Upload File
              </>
            )}
          </button>

          {/* Progress Bar */}
          {isUploading && (
            <div className="mt-4">
              <div className="h-2 overflow-hidden bg-gray-200 rounded-full">
                <div
                  className="h-full transition-all duration-300 bg-blue-500"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <p className="mt-2 text-sm text-center text-gray-600">
                {uploadProgress}% uploaded
              </p>
            </div>
          )}

          {/* Error Message */}
          {error && (
            <div className="p-4 mt-4 border border-red-200 rounded-lg bg-red-50">
              <div className="flex items-center text-red-700">
                <AlertCircle className="w-5 h-5 mr-2" />
                <span>{error}</span>
              </div>
            </div>
          )}

          {/* Success Message with Link */}
          {link && (
            <div className="p-4 mt-6 border border-green-200 rounded-lg bg-green-50">
              <div className="flex items-center mb-2">
                <CheckCircle2 className="w-5 h-5 mr-2 text-green-500" />
                <p className="font-medium text-green-700">File uploaded successfully!</p>
              </div>

              {userRole === 'public' && (
                <p className="mb-2 text-sm text-gray-600">
                  Your file link (valid for 7 days):
                </p>
              )}

              <div className="flex items-center gap-2 mt-2">
                <a href={link} target="_blank" rel="noopener noreferrer" className="flex-1 text-blue-600 break-all hover:text-blue-700">
                  {link}
                </a>
                <button onClick={copyToClipboard} className="flex items-center px-3 py-2 text-sm font-medium text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700">
                  <Link2 className="w-4 h-4 mr-2" />
                  {copySuccess ? 'Copied!' : 'Copy Link'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Auth Modal */}
      {showAuthModal && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          onSubmit={handleTokenSubmit}
          error={tokenError}
        />
      )}
    </div>
  );
};

export default FileUpload;